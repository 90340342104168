// ==================== primary button colors ====================
$primary-button-light-color:#FFE200;
$primary-button-dark-color:#FFA558;
$primary-button-light-color-hover:#FACA00;
$primary-button-dark-color-hover:#FF7A27;
$primary-button-shadow:#FFD209;


// ==================== primary button colors ====================
$secondary-button1-light-color:#548efe;
$secondary-button1-dark-color:#2a63cf;
$secondary-button1-light-color-hover:#206bff;
$secondary-button1-dark-color-hover:#2a65d4;
$secondary-button1-shadow:#1450be;


// ==================== secondary gray button colors ====================
$secondary-button1-color:#939393;

// ==================== text colors ====================
$primary-blue-color:#2F8CF7;
$primary-gray-color:#777777;
$features-title-color:#367BFF;
$coming-soon-title-color:#3F61A3;