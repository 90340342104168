@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,700');
@import url('https://fonts.googleapis.com/css?family=Abril+Fatface');
@import url('https://fonts.googleapis.com/css?family=Heebo:300,400');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,900&display=swap');
@import "./base";

*{
    font-family: 'Fira Sans', sans-serif;
}
*:focus{
    outline: none !important;
}

button:active {
    outline: none;
}
button:focus {outline:0;}
 

.app_container{margin:0 7%}

.row{
    margin-right: 0;
    margin-left: 0;
}

.card{
    margin:10px 0;
    -webkit-box-shadow:0 0 10px #d9d9d9;
    -moz-box-shadow:0 0 10px #d9d9d9;
    border-radius:20px!important;
    box-shadow:0 10px 30px rgba(113, 117, 226, 0.212);
    border: none !important;
}

.input_rounded{
    width:100%;
    padding:12px 20px;
    margin:8px 0;
    box-sizing:border-box;
    outline:0;
    border:1px solid #b7b7b7;
    border-radius:30px; 
    color: #777777;
    background: #FFFFFF;
}

textarea{
    resize: none;
}

// ==================== primary button style ====================
.primary_button {
    -moz-box-shadow: 0px 2px 11px -2px $primary-button-shadow;
	-webkit-box-shadow: 0px 2px 11px -2px $primary-button-shadow;
	box-shadow: 0px 2px 11px -2px $primary-button-shadow;
    background-color:$primary-button-light-color;
    background-image:-webkit-linear-gradient(45deg,$primary-button-dark-color,$primary-button-light-color);
    background-image:-moz-linear-gradient(45deg$primary-button-dark-color,$primary-button-light-color);
    background-image:-ms-linear-gradient(45deg,$primary-button-dark-color,$primary-button-light-color);
    background-image:-o-linear-gradient(45deg,$primary-button-dark-color,$primary-button-light-color);
    background-image:linear-gradient(45deg,$primary-button-dark-color,$primary-button-light-color);
    border: none;
	-webkit-border-radius:28px;
	border-radius:28px;
	display:inline-block;
	cursor:pointer;
	color:#FFFFFF;
	font-family:Arial;
    font-size:$paragraph2;
    font-weight: bold;
	padding:8px 30px;
    text-decoration:none;
    outline: none !important;

}
.primary_button:hover {
    background-color:$primary-button-light-color-hover;
    background-image:-webkit-linear-gradient(45deg,$primary-button-dark-color-hover,$primary-button-light-color-hover);
    background-image:-moz-linear-gradient(45deg$primary-button-dark-color-hover,$primary-button-light-color-hover);
    background-image:-ms-linear-gradient(45deg,$primary-button-dark-color-hover,$primary-button-light-color-hover);
    background-image:-o-linear-gradient(45deg,$primary-button-dark-color-hover,$primary-button-light-color-hover);
    background-image:linear-gradient(45deg,$primary-button-dark-color-hover,$primary-button-light-color-hover);
}
.primary_button:active {
	position:relative;
	top:1px;
}


// ==================== secondary button1 style ====================
.secondary_button1 {
    -moz-box-shadow: 0px 2px 11px -2px $secondary-button1-shadow;
	-webkit-box-shadow: 0px 2px 11px -2px $secondary-button1-shadow;
	box-shadow: 0px 2px 11px -2px $secondary-button1-shadow;
    background-color:$secondary-button1-light-color;
    background-image:-webkit-linear-gradient(45deg,$secondary-button1-dark-color,$secondary-button1-light-color);
    background-image:-moz-linear-gradient(45deg$secondary-button1-dark-color,$secondary-button1-light-color);
    background-image:-ms-linear-gradient(45deg,$secondary-button1-dark-color,$secondary-button1-light-color);
    background-image:-o-linear-gradient(45deg,$secondary-button1-dark-color,$secondary-button1-light-color);
    background-image:linear-gradient(45deg,$secondary-button1-dark-color,$secondary-button1-light-color);
    border: none;
	-webkit-border-radius:28px;
	border-radius:28px;
	display:inline-block;
	cursor:pointer;
	color:#FFFFFF;
	font-family:Arial;
    font-size:$paragraph2;
    font-weight: bold;
	padding:8px 30px;
    text-decoration:none;
    outline: none !important;
}
.secondary_button1:hover {
    background-color:$secondary-button1-light-color-hover;
    background-image:-webkit-linear-gradient(45deg,$secondary-button1-dark-color-hover,$secondary-button1-light-color-hover);
    background-image:-moz-linear-gradient(45deg$secondary-button1-dark-color-hover,$secondary-button1-light-color-hover);
    background-image:-ms-linear-gradient(45deg,$secondary-button1-dark-color-hover,$secondary-button1-light-color-hover);
    background-image:-o-linear-gradient(45deg,$secondary-button1-dark-color-hover,$secondary-button1-light-color-hover);
    background-image:linear-gradient(45deg,$secondary-button1-dark-color-hover,$secondary-button1-light-color-hover);
}
.secondary_button1:active {
	position:relative;
	top:1px;
}


.secondary_button2{
    background-color: $secondary-button1-color;
    border: none;
	-webkit-border-radius:28px;
	border-radius:28px;
	display:inline-block;
	cursor:pointer;
	color:#FFFFFF;
	font-family:Arial;
    font-size:$paragraph2;
    font-weight: bold;
	padding:8px 30px;
    text-decoration:none;
    outline: none !important;
}



//==================== section title ====================
.title_cloud_container{
    width: 100%;
    text-align: center;

    .title_left_part{
        width: 35%;
    }

    .title_right_part{
        width: 35%;
    }

    .section_title{
        margin-top: 30px;
        color: $primary-blue-color;
        font-size: $header2;
        font-weight: bold;
    }
}


// text strikethrough
.strike{
    text-decoration: line-through;
}

.navbar-light .navbar-toggler{border-color:transparent!important}

#navbarSupportedContent > div > ul > a:nth-child(1),
#navbarSupportedContent > div > ul > a:nth-child(2),
#navbarSupportedContent > div > ul > a:nth-child(3),
#navbarSupportedContent > div > ul > a:nth-child(4),
#navbarSupportedContent > div > ul > a:nth-child(5)
{
    padding-top: 20px;
}


#navbarSupportedContent > div > ul{
    text-align: center;
    width: 100%;
}



.modal-backdrop{
    display: none;
}
.modal-content{
    // box-shadow: 0px 0px 220px grey !important;
    background-color: red;
    position: relative;
}

.modal {
    z-index: 1101;
    background-color: rgba(0, 0, 0, 0.7);
}

.fullWidth{
    width: 100%;
}
.navbar-nav{
    z-index: 1;
}


@media screen and (max-width:768px){
    nav > a > img{
        transform: translateX(-50%);
        position: absolute;
        left: 50%;
        top: 10px;
    }
    .card{border:0 solid rgba(0,0,0,.125)!important;box-shadow:0 0 10px #fff!important}
}





.wp-block-image, .wp-block-gallery{
    text-align: center;
    list-style: none;
    img{
        max-width: 100%;
    }
}

.wp-caption{
    img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        height: auto;
    }
}

p, h4{
    .aligncenter{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        height: auto;
    }
}

.detail_content{
    h1,h2,h3,h4,h5,h6{
        font-weight: 700;
    }
}
.blog_date_author{
        color: #404040;
}




/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 992px) {
    p, h4{
        .aligncenter{
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            height: auto;
        }
    }

    .wp-caption{
        img{
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            height: auto;
        }
    }
} 


.blog_list_content > p{
    img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        height: auto;
    }
}


.modal-header, .modal-body, .modal-footer{
background-color: #ffffff;
}
.modal-header{
    border-radius: 10px 10px 0 0 !important;
}
.modal-footer{
    border-radius: 0 0 10px 10px;
}